
.list-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
}
.info-step-header {
    padding: 22px 0 0;
}
.big-title {
    font-size: 20px;
    line-height: 1;
    margin: 20px 0;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    .money {
        font-size: 18px;
        color: #FD4446;
        span {
            font-size: 12px;
            margin-right: 2px;
        }
    }
}
.main-box {
    height: 1%;
    flex: 1;
    background: #fff;
    margin-bottom: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .tab-box {
        font-size: 20px;
        color: #FD4446;
        line-height: 1;
        .item {
            position: relative;
            display: inline-block;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -10px;
                height: 2px;
                background: #FD4446;
            }

        }
    }
    .operate-box {
        margin-top: 30px;
        display: flex;
        .end-number {
            color: #95D476;
            line-height: 38px;
            background: #F0F9EB;
            border-radius: 4px;
            border: 1px solid #E3F4DB;
            margin-left: 10px;
            font-size: 16px;
            padding: 0 16px;
            span {
                font-weight: 500;
            }
            &.disabled {
                color: #9DA0A5;
                background: #F4F4F5;
                border: 1px solid #EEEFF0;
            }
        }
    }
}
.blue {
    color: #1E63F1;
    cursor: pointer;
}
.showEdit-icon {
    .iconfont {
        color: #333;
        font-size: 14px;
        margin-left: 10px;
        display: none;
    }
    &:hover {
        .iconfont {
            display: inline-block;
        }
    }
}
